<template>
  <b-input-group>
    <!-- Prefix Mata Uang -->
    <b-input-group-prepend is-text>{{ currencySymbol }}</b-input-group-prepend>
    
    <!-- Input dengan BootstrapVue -->
    <b-form-input
      v-model="formattedPrice"
      :placeholder="placeholder"
      @input="formatPrice"
      @blur="finalizePrice"
    />
  </b-input-group>
</template>



<script>

import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend
} from 'bootstrap-vue'

export default {
  name: "CurrencyInput",
  functional: true,
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend
  },
  props: {
    value: Number, // Menggunakan v-model
    currency: {
      type: String,
      default: "IDR" // Default ke Rupiah
    },
    locale: {
      type: String,
      default: "id-ID" // Default Indonesia
    },
    placeholder: {
      type: String,
      default: "Masukkan nominal"
    }
  },
  computed: {
    formattedPrice: {
      get() {
        return this.value ? this.value.toLocaleString(this.locale) : "";
      },
      set(val) {
        let numericValue = Number(val.replace(/\D/g, ""));
        this.$emit("input", numericValue);
      }
    },
    currencySymbol() {
      // Menentukan simbol berdasarkan currency
      const symbols = {
        IDR: "Rp",
        USD: "$",
        EUR: "€"
      };
      return symbols[this.currency] || this.currency; // Default ke kode jika tidak ada
    }
  },
  methods: {
    formatPrice(event) {
      let value = event.target.value.replace(/\D/g, ""); // Hanya angka
      this.$emit("input", Number(value));
    },
    finalizePrice() {
      if (this.value) {
        this.formattedPrice = new Intl.NumberFormat(this.locale, {
          style: "currency",
          currency: this.currency
        }).format(this.value);
      }
    }
  }
};
</script>
  